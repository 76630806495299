import Vue from 'vue';
import Clipboard from 'clipboard';

function copySuccess() {
  Vue.prototype.$message({
    message: '复制成功',
    type: 'success',
  });
}

function copyFail() {
  Vue.prototype.$message({
    message: '该浏览器不支持自动复制',
    type: 'warning',
  });
}

/**
 *
 * @param e 事件对象
 * @param text 复制的内容
 */
export default function copyText(e, text) {
  const clipboard = new Clipboard(e.target, {
    text: () => (typeof text == 'object' ? JSON.stringify(text) : text),
  });
  clipboard.on('success', () => {
    copySuccess();
    // 释放内存
    clipboard.destroy();
  });
  clipboard.on('error', () => {
    // 不支持复制
    copyFail();
    // 释放内存
    clipboard.destroy();
  });
  // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效
  clipboard.onClick(e);
}
